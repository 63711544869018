import React from 'react'

import { PATH, formatPrice } from '../../../../constants/global'
import { TABLE } from '../../../../constants/tables'
import { renderDateOrNothing } from '../../../../common/utils/functions'
import { TrueOrFalse } from '../../../common'
import * as tableStyles from '../../../common/table/table.module.scss'

export const BASIC_TABLE = {
   basicPath: PATH.COSTING_HISTORY,
   downloadCsvText: 'Export to Excel',
   extraClassName: 'table-basic',
   header: 'Title',
   key: 'CostingData',
   modalTitle: 'Download Excel File',
   placeholder: ' costing history',
   tableTitle: TABLE.TITLE.COSTING_HISTORY,
   primaryActionText: 'Export',
   secondaryActionText: 'Cancel',
   exportFileName: 'CostingHistory.xlsx',
   columnHeaders: [
      {
         Header: 'User',
         accessor: 'BorrowingUserDisplayName',
         Cell: (cell: {
            row: { original: { BorrowingUserDisplayName: string } }
         }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               {cell.row.original.BorrowingUserDisplayName}
            </div>
         ),
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Location',
         accessor: 'ToolLoanLocation',
         Cell: (cell: { row: { original: { ToolLoanLocation: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               {cell.row.original.ToolLoanLocation}
            </div>
         ),
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Item Name',
         accessor: 'Title',
         Cell: (cell: { row: { original: { Title: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               {cell.row.original.Title}
            </div>
         ),
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Type',
         accessor: 'ToolTypeDesc',
         align: 'center',
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Qty',
         accessor: 'Quantity',
         align: 'center',
         width: 75,
         minWidth: 20,
      },
      {
         Header: 'Item Detail',
         accessor: 'Description',
         Cell: (cell: { row: { original: { Description: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               {cell.row.original.Description}
            </div>
         ),
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Manufacturer',
         accessor: 'ManufacturerName',
         align: 'center',
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Model No.',
         accessor: 'ModelNumber',
         align: 'center',
         width: 120,
         minWidth: 20,
      },
      {
         Header: 'Serial No.',
         accessor: 'SerialNumber',
         align: 'center',
         width: 120,
         minWidth: 20,
      },
      {
         Header: 'Barcode',
         accessor: 'ItemBarcode',
         align: 'center',
         width: 120,
         minWidth: 20,
      },
      {
         Header: 'Category',
         accessor: 'CategoryName',
         align: 'center',
         width: 120,
         minWidth: 20,
      },
      {
         Header: 'Purchase Date',
         accessor: 'PurchaseDate',
         align: 'center',
         width: 120,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Purchase Price',
         accessor: 'PurchasePrice',
         align: 'center',
         width: 120,
         minWidth: 20,
      },
      {
         Header: 'Warranty Date',
         accessor: 'WarrantyDate',
         align: 'center',
         width: 120,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Rental Tool',
         accessor: 'IsRentalTool',
         align: 'center',
         width: 130,
         minWidth: 20,
         Cell: ({ row }) => (
            <TrueOrFalse status={row?.original?.IsRentalTool} />
         ),
      },
      {
         Header: 'Rental Start Date',
         accessor: 'RentalDate',
         align: 'center',
         width: 120,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Rental End Date',
         accessor: 'RentalReturnDate',
         align: 'center',
         width: 120,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Rate-Day',
         accessor: 'CustomRentalRate1',
         align: 'center',
         width: 110,
         minWidth: 20,
         Cell: ({ row }) => formatPrice(row?.original?.CustomRentalRate1),
      },
      {
         Header: 'Rate-Week',
         accessor: 'CustomRentalRate2',
         align: 'center',
         width: 110,
         minWidth: 20,
         Cell: ({ row }) => formatPrice(row?.original?.CustomRentalRate2),
      },
      {
         Header: 'Monthly Rate',
         accessor: 'CustomRentalRate3',
         align: 'center',
         width: 110,
         minWidth: 20,
         Cell: ({ row }) => formatPrice(row?.original?.CustomRentalRate3),
      },
      {
         Header: 'Hourly Rate',
         accessor: 'CustomRentalRate4',
         align: 'center',
         width: 110,
         minWidth: 20,
         Cell: ({ row }) => formatPrice(row?.original?.CustomRentalRate4),
      },
      {
         Header: 'Custom Date 1 Value',
         accessor: 'CustomDate1Value',
         align: 'center',
         width: 120,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Custom Date 2 Value',
         accessor: 'CustomDate2Value',
         align: 'center',
         width: 120,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Custom Text 1 Value',
         accessor: 'CustomText1Value',
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Custom Text 2 Value',
         accessor: 'CustomText2Value',
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Custom URL 1',
         accessor: 'CustomURL1Value',
         Cell: (cell: { row: { original: { CustomURL1Value: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               <a href={cell.row.original.CustomURL1Value} target={'_blank'}>
                  {cell.row.original.CustomURL1Value}
               </a>
            </div>
         ),
      },
      {
         Header: 'Custom URL 2',
         accessor: 'CustomURL2Value',
         Cell: (cell: { row: { original: { CustomURL2Value: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               <a href={cell.row.original.CustomURL2Value} target={'_blank'}>
                  {cell.row.original.CustomURL2Value}
               </a>
            </div>
         ),
      },
      {
         Header: 'Website URL',
         accessor: 'WebsiteUrl',
         Cell: (cell: { row: { original: { WebsiteUrl: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               <a href={cell.row.original.WebsiteUrl} target={'_blank'}>
                  {cell.row.original.WebsiteUrl}
               </a>
            </div>
         ),
         width: 130,
         minWidth: 20,
      },
      {
         Header: 'Owners Manual URL',
         accessor: 'AttachmentUrl',
         Cell: (cell: { row: { original: { AttachmentUrl: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               <a href={cell.row.original.AttachmentUrl} target={'_blank'}>
                  {cell.row.original.AttachmentUrl}
               </a>
            </div>
         ),
         width: 130,
         minWidth: 20,
      },
      {
         Header: 'Return Warehouse',
         accessor: 'DefaultLocation',
         Cell: (cell: { row: { original: { DefaultLocation: string } } }) => (
            <div className={tableStyles.tableColumnCellWrapper}>
               {cell.row.original.DefaultLocation}
            </div>
         ),
         width: 200,
         minWidth: 20,
      },
      {
         Header: 'Loan Date',
         accessor: 'DateAccepted',
         align: 'center',
         width: 120,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Due Back',
         accessor: 'PlannedLoanReturnDisplay',
         align: 'center',
         width: 120,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Return Date',
         accessor: 'ReturnDate',
         align: 'center',
         width: 120,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Report Start Date',
         accessor: 'ReportStartDate',
         align: 'center',
         width: 120,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Report End Date',
         accessor: 'ReportEndDate',
         align: 'center',
         width: 120,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Duration Day(s)',
         accessor: 'DurationDays',
         align: 'center',
         width: 110,
         minWidth: 20,
      },
      {
         Header: 'Duration Hour(s)',
         accessor: 'DurationTimeHrs',
         align: 'center',
         width: 110,
         minWidth: 20,
      },
      {
         Header: 'Total Duration Day(s)',
         accessor: 'LoanDuration',
         align: 'center',
         width: 110,
         minWidth: 20,
      },
   ],
   initialPayload: {
      type: 0,
      startDate: null,
      endDate: null,
      selectedConnections: [],
      selectedLocations: [],
      selectedCategories: [],
      onlyToolsMarkedAsRental: false,
      offset: null,
      skip: 0,
      take: 10,
      query: '',
   },
   downloadRequest: {
      type: 0,
      startDate: '',
      endDate: '',
      selectedConnections: [],
      selectedLocations: [],
      selectedCategories: [],
      onlyToolsMarkedAsRental: false,
      offset: null,
      skip: 0,
      take: 10,
      query: null,
   },
}
